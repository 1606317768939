import { Injectable } from '@angular/core';
import { KeyValueCategory } from 'app/common/enums';
import { KeyValueStorage } from 'app/common/storage/key-value-storage';
import { LocalStorage } from 'app/common/storage/local-storage';
import { StorageAccessor } from 'app/common/storage/storage-accessor';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private storage: KeyValueStorage;

    public init(): Promise<void> {
        this.createStorage();
        return this.storage.init();
    }

    private createStorage() {
        this.storage = new LocalStorage();
    }

    public getStorageAccessor(storageCategory: KeyValueCategory) {
        return new StorageAccessor(this.storage, storageCategory);
    }

    public resetFilters() {
        return this.storage.clear(KeyValueCategory.CATEGORY_FILTER);
    }

    public resetTableStates() {
        return this.storage.clear(KeyValueCategory.CATEGORY_TABLE);
    }
}
