import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OrgService } from 'app/state/organisation/org.service';
import { TransferStateService } from 'app/state/transfer/transfer-state.service';
import { UserStateService } from 'app/state/user';

import { HttpService } from '../services/http.service';
import { Utils } from './utils';

export const CanActivateAuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const jwtHelperService = inject(JwtHelperService);
    const router = inject(Router);
    const httpService = inject(HttpService);
    const userStateService = inject(UserStateService);
    const transferStateService = inject(TransferStateService);
    const orgService = inject(OrgService);

    const url: string = state.url;

    try {
        const expired: boolean = jwtHelperService.isTokenExpired(localStorage.getItem('token'));
        if (!expired) {
            const userInfo = Utils.getUserInfoFromToken();
            if (url === '/') {
                // log out contacts and school representative if they visit the main page
                const notAllowedRole = userInfo?.isSchoolContact() || userInfo?.isSchoolRepresentative();
                if (notAllowedRole) {
                    return logOut(router, httpService, userStateService, transferStateService, orgService);
                }
                if (userInfo.viewMode === 'org') {
                    router.navigate([`orgs/${userInfo.organisationId}/summary`]);
                } else {
                    router.navigate(['dashboard']);
                }
            }
            return Utils.isAuthorized(userInfo, route.data.roles);
        }
        return logOut(router, httpService, userStateService, transferStateService, orgService);
    } catch (error) {
        console.error(error);
        return logOut(router, httpService, userStateService, transferStateService, orgService);
    }
};

const logOut = (
    router: Router,
    httpService: HttpService,
    userStateService: UserStateService,
    transferStateService: TransferStateService,
    orgService: OrgService
) => {
    httpService.updateCurrentSchoolId(null);
    httpService.updateCurrentOrgId(null);
    Utils.logout(router);
    userStateService.resetStore();
    transferStateService.unsubscribeFromTransfer();
    orgService.resetStore();
    return false;
};
