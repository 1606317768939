import { ZingCommonFontStyle, ZingData, ZingGUI, ZingGlobals, ZingNoData, ZingSeries } from '../entities/local/zing-chart';
import { getBackgroundAlpha, getColor } from './chart-utils';

export class ChartInitData {
    public static getPieChartInitData(): ZingData {
        return {
            type: 'pie',
            title: {
                text: '',
                ...ZingCommonFontStyle,
                position: '50% 100%',
            },
            noData: ZingNoData.getNoData(),
            plotarea: {
                margin: '0px 0px 12px 0px',
            },
            series: [],
            plot: {
                detach: false,
                tooltip: {
                    alpha: 1,
                    ...ZingCommonFontStyle,
                    text: '<div class="pie-chart-tooltip text-center" style="border-color:%color"><span style="color:%data-background-color">%t <br/> %v | %npv%</span</div>',
                    borderWidth: 0,
                    borderRadius: 8,
                    placement: 'node:out',
                    backgroundColor: '#ffffff',
                    padding: '0',
                    htmlMode: true,
                    decimals: 0,
                    thousandsSeparator: ',',
                    rules: [],
                },
                slice: '65%',
                valueBox: {
                    ...ZingCommonFontStyle,
                    text: '%npv%',
                    decimals: 0,
                    connector: {
                        lineColor: '#616161',
                    },
                    rules: [
                        {
                            text: '%npv',
                            rule: '%node-value == 0',
                            visible: false,
                        },
                    ],
                },
                rules: [
                    {
                        text: '%p',
                        rule: '%p <= 0.0',
                        visible: false,
                    },
                ],
            },
            labels: [
                {
                    fontWeight: 500,
                    anchor: 'c',
                    x: '50%',
                    y: '48%',
                    fontSize: 50,
                    text: '',
                    tooltip: {
                        text: '',
                    },
                },
            ],
            gui: ZingGUI.getDefaultConfig(),
        };
    }

    public static getColumnChartInitData(maxChars?: number): ZingData {
        return {
            type: 'bar',
            plotarea: {
                adjustLayout: true,
            },
            noData: ZingNoData.getNoData(),
            preview: {
                visible: false,
                mask: {},
                label: {},
            },
            plot: {
                stacked: true,
                borderRadius: 0,
                valueBox: {
                    ...ZingCommonFontStyle,
                    text: '',
                    thousandsSeparator: ',',
                    rules: [
                        {
                            text: '',
                            rule: '%stack-top == 0',
                            visible: false,
                        },
                    ],
                },
                tooltip: {
                    backgroundColor: 'white',
                    border: 'none',
                    ...ZingCommonFontStyle,
                    text: '<span style="color:%data-background-color">%t: %v</span>',
                    visible: true,
                    thousandsSeparator: ',',
                    rules: [
                        {
                            text: '%v',
                            rule: '%v <= 0',
                            visible: false,
                        },
                    ],
                },
            },
            scaleX: {
                labels: [],
                maxItems: 10,
                itemsOverlap: true,
                item: {
                    angle: '0',
                    maxChars,
                },
                tooltip: {
                    text: '%data-tooltip',
                },
                transform: {},
            },
            scaleY: {
                label: ZingCommonFontStyle,
                autoFit: true,
            },
            series: [],
            gui: ZingGUI.getDefaultConfig(),
        };
    }

    public static getLineAreaChartInitData(): ZingData {
        return {
            type: 'area',
            plotarea: { adjustLayout: true },
            noData: ZingNoData.getNoData(),
            crosshairX: {
                lineStyle: 'dashed',
                lineWidth: 2,
                visible: true,
                plotLabel: {
                    fontSize: 12,
                    text: '<strong style="color:%data-background-color">%t:</strong> %v',
                    thousandsSeparator: ',',
                },
                scaleLabel: {
                    backgroundColor: 'black',
                    fontColor: 'white',
                    fontSize: 12,
                },
                marker: {
                    visible: false,
                },
            },
            preview: {
                visible: false,
                mask: {},
                label: {},
            },
            plot: {
                tooltip: {
                    text: '<span style="background-color:%data-background-color;color:white">%t: %v</span>',
                    visible: false,
                },
                valueBox: {
                    ...ZingCommonFontStyle,
                    text: '',
                    thousandsSeparator: ',',
                    rules: [
                        {
                            text: '',
                            rule: '%stack-top == 0',
                            visible: false,
                        },
                    ],
                },
            },
            scaleX: {
                labels: [],
                maxItems: 10,
                item: {
                    angle: '0',
                },
                transform: {},
            },
            scaleY: {
                label: ZingCommonFontStyle,
                autoFit: true,
            },
            series: [],
            gui: ZingGUI.getDefaultConfig(),
        };
    }
    public static getHeatMapChartInitData(): ZingData {
        return {
            type: 'heatmap',
            plotarea: { adjustLayout: true },
            plot: {
                aspect: 'none',
                borderRadius: 7,
                borderWidth: 2,
                borderColor: 'white',
                valueBox: {
                    text: '',
                    rules: [],
                    fontWeight: 400,
                    thousandsSeparator: ',',
                },
                rules: [],
                tooltip: {
                    text: '%v',
                    visible: false,
                },
            },
            scaleX: {
                labels: [],
                lineColor: 'none',
                guide: {
                    visible: false,
                },
                tick: {
                    visible: false,
                },
                placement: 'opposite',
                item: {
                    angle: '0',
                },
            },
            scaleY: {
                maxItems: 10,
                labels: [],
                lineColor: 'none',
                guide: {
                    visible: false,
                },
                tick: {
                    visible: false,
                },
                label: { text: '' },
            },
            noData: ZingNoData.getNoData(),
            series: [],
            globals: ZingGlobals.getDefaultConfig(),
            gui: ZingGUI.getDefaultConfig(),
        };
    }
    public static getGridChartInitData(): ZingData {
        return {
            type: 'grid',
            plotarea: {
                margin: '0 15 0 15',
            },
            header: [],
            options: {},
            noData: ZingNoData.getNoData(),
            series: [],
            gui: ZingGUI.getDefaultConfig(),
        };
    }

    public static getMixedLineChartInitData(): ZingSeries {
        return {
            type: 'line',
            values: [],
            text: '',
            marker: {
                backgroundColor: '#9e9e9e',
            },
            dataBackgroundColor: '#9e9e9e',
            lineColor: '#9e9e9e',
            lineStyle: '',
            valueBox: {
                text: '',
                rules: [],
            },
        };
    }

    public static getFunnelChartInitData(): ZingData {
        return {
            type: 'funnel',
            scaleX: {
                visible: true,
                labels: [],
                placement: 'opposite',
                maxItems: 15,
            },
            scaleY: {
                labels: [],
                guide: {
                    lineColor: 'white',
                    items: [
                        {
                            backgroundColor: getColor('series-interest'),
                            borderRadius: '8px 8px 0px 0px',
                            alpha: getBackgroundAlpha('series-interest'),
                        },
                        {
                            backgroundColor: getColor('series-applicant'),
                            borderRadius: '0px',
                            alpha: getBackgroundAlpha('series-applicant'),
                        },
                        {
                            backgroundColor: getColor('series-enrolled'),
                            borderRadius: '0px 0px 12px 12px',
                            alpha: getBackgroundAlpha('series-enrolled'),
                        },
                    ],
                },
            },
            series: [
                { values: [], backgroundColor: getColor('series-interest'), text: '' },
                { values: [], backgroundColor: getColor('series-applicant'), text: '' },
                { values: [], backgroundColor: getColor('series-enrolled'), text: '' },
            ],
            plotarea: {
                margin: 'dynamic',
            },
            plot: {
                tooltip: {
                    alpha: 0.9,
                    backgroundColor: 'white',
                    borderColor: 'black',
                    borderWidth: 1,
                    borderRadius: 2,
                    fontSize: 12,
                    padding: '5 10',
                    text: 'Percentage (relative to previous stage): %output-percent-value%',
                },
                valueBox: {
                    text: '%v',
                    placement: 'right-out',
                    thousandsSeparator: ',',
                    fillOffsetX: '150px',
                    rules: [
                        {
                            rule: '%v === 0',
                            text: '',
                        },
                    ],
                },
            },
            globals: ZingGlobals.getDefaultConfig(),
            gui: ZingGUI.getDefaultConfig(),
        };
    }
}
