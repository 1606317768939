import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

import { PageLeaveReason } from '../common/enums';

declare var $: any;

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    public showPageLeaveDialog(msg: string, showSaveButton: boolean = true): Promise<number> {
        return new Promise(resolve => {
            const buttons = $('<div><h4>' + msg + '</h4><br/>')
                .append(this.addButton('Go back', 'cancel pageLeaveGoBackBtn'))
                .append(this.addButton("Don't save", 'danger pageLeaveLeaveBtn'))
                .append(this.addButton('Save', 'success pageLeaveSaveBtn', !showSaveButton));

            Swal.fire({
                title: '',
                html: buttons,
                icon: 'warning',
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: swal1 => {
                    $(swal1)
                        .find('.pageLeaveSaveBtn')
                        .off()
                        .click(e => {
                            Swal.close();
                            return resolve(PageLeaveReason.save);
                        });
                    $(swal1)
                        .find('.pageLeaveGoBackBtn')
                        .off()
                        .click(e => {
                            Swal.close();
                            return resolve(PageLeaveReason.goBack);
                        });
                    $(swal1)
                        .find('.pageLeaveLeaveBtn')
                        .off()
                        .click(e => {
                            Swal.close();
                            return resolve(PageLeaveReason.doNotSave);
                        });
                },
            });
        });
    }

    private addButton(name: string, color: string, disabled = false) {
        const dsbld = disabled ? 'disabled' : '';
        return $('<button class="btn btn-' + color + '" ' + dsbld + '>' + name + '</button>');
    }
}
